
.DraggableControl {
    position: absolute !important;
    z-index: 100;
    top: 8px;
    left: 12px;
  }

.DrawerRoot {
    padding: 28px;
}